import PromptBubble from '../OIterationDetails/MPromptBubble.tsx'
import { useRoleMessageCommand } from '@/lib/iterations/analyst/useRoleMessageCommands.ts'
import { useUserCommandQuery } from '@/lib/iterations/userUserCommandQuery.ts'

export function LastUpdatePromptBubble({ iterationId }: { iterationId: string }) {
  const updates = useRoleMessageCommand(iterationId)
  const commandId = updates?.response?.id ?? null

  const userCommand = useUserCommandQuery(commandId)
  const isUpdate =
    updates?.command === 'ROLE_MESSAGE' && updates.commandArgs.content.choice === 'update'
  if (!isUpdate) {
    return null
  }

  return (
    <PromptBubble status={userCommand?.data?.status}>
      {updates.commandArgs.content.message}
    </PromptBubble>
  )
}
