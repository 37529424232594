import { z } from 'zod'
import {
  GUNSLINGER_JOB_STATUSES_VALUES,
  ITERATION_COMMANDS,
  ITERATION_STATUS_VALUES,
} from '../const/const'
import { isDate } from 'lodash'

// Timestamp schema
const TimestampSchema = z
  .union([
    z.object({
      seconds: z.number(),
      nanoseconds: z.number(),
    }),
    z.date(),
  ])
  .transform((data): Date => (isDate(data) ? data : new Date(data.seconds * 1000)))

export const AGENT_ROLE_STATUS = [
  'not_started',
  'in_progress',
  'waiting_for_message',
  'done',
] as const

export type AgentRoleStatus = (typeof AGENT_ROLE_STATUS)[number]

const AgentRoleSchema = z.object({
  name: z.string(),
  status: z.enum(AGENT_ROLE_STATUS),
  turn: z
    .object({
      label: z.number(),
      name: z.string(),
    })
    .optional(),
})

export type AgentRole = z.infer<typeof AgentRoleSchema>

const RoleRegistrySchema = z.record(z.string(), AgentRoleSchema)

export type RoleRegistry = z.infer<typeof RoleRegistrySchema>

// Configuration schema
const ConfigurationSchema = z.object({
  name: z.string(),
  prospectName: z.string(),
  prospectWebsite: z.string(),
  techStack: z.string(),
  organizationId: z.string(),
  teamId: z.string(),
})

// Action schema
const ActionSchema = z.object({
  args: z.record(z.any()),
  actionId: z.string(),
  elementId: z.string(),
  stepId: z.string(),
  name: z.string(),
  obsolete: z.boolean(),
  index: z.number(),
  iterationId: z.string(),
  hideArgsFromPrompt: z.boolean(),
  id: z.string(),
  type: z.string(),
  status: z.string(),
  createdAt: TimestampSchema,
  updatedAt: TimestampSchema,
  elementIndex: z.number(),
  stepIndex: z.number(),
  outputs: z.array(z.any()).optional(),
  startCommitHash: z.string().nullable(),
  endCommitHash: z.string().nullable(),
  startSnapshotHash: z.string().nullable(),
  endSnapshotHash: z.string().nullable(),
  subtype: z.string().nullable(),
  startedAt: z.number().nullable(),
  endedAt: z.number().nullable(),
  elapsedTime: z.number().nullable(),
})

export type IterationAction = z.infer<typeof ActionSchema>

// Step schema
const StepSchema = z.object({
  services: z.array(z.any()),
  libraries: z.array(z.any()),
  elementId: z.string(),
  stepId: z.string(),
  language: z.string(),
  name: z.string(),
  iterationId: z.string(),
  id: z.string(),
  index: z.number(),
  type: z.string(),
  description: z.string(),
  createdAt: TimestampSchema,
  updatedAt: TimestampSchema,
  status: z.string(),
  elementIndex: z.number(),
  actions: z.record(z.string(), ActionSchema),
  type_class: z.string(),
  max_loop_degree: z.number(),
  loop_degree: z.number(),
  output: z.any().nullable(),
  role_registry: RoleRegistrySchema,
})

export type IterationStep = z.infer<typeof StepSchema>

// Element schema
const ElementSchema = z.object({
  research: z.array(z.any()),
  elementId: z.string(),
  name: z.string(),
  stack: z.array(z.string()),
  keep_server_down: z.boolean(),
  iterationId: z.string(),
  id: z.string(),
  index: z.number(),
  description: z.string(),
  assessment: z.string(),
  createdAt: TimestampSchema,
  updatedAt: TimestampSchema,
  documentation: z.string(),
  status: z.string(),
  steps: z.record(z.string(), StepSchema),
  role_registry: RoleRegistrySchema,
})

export type IterationElement = z.infer<typeof ElementSchema>

const ContinuationPromptSchema = z.object({
  afterElementIndex: z.number(),
  prompt: z.string(),
  createdBy: z.string(),
})

const RequestSchema = z.object({
  request: z.string(),
  type: z.enum(['text', 'secret']),
  user_value: z.string(),
})

export const AwaitingInputCommandSchema = z
  .object({
    id: z.string(),
    command: z.literal('INPUT_REQUEST'),
    args: z.object({
      requests: z.array(RequestSchema),
    }),
    active: z.boolean(),
  })
  .nullable()

export type AwaitingInputCommand = z.infer<typeof AwaitingInputCommandSchema>

export const EncryptedValueSchema = z.object({
  value: z.string(),
  isEncrypted: z.boolean(),
  encryption: z
    .object({
      // not present if isEncrypted is false
      keyResourceName: z.string(),
      vendor: z.string(),
    })
    .optional(),
})

export type EncryptedValue = z.infer<typeof EncryptedValueSchema>

const PreviewUrlSchema = z.object({
  label: z.string(),
  url: z.string(),
  technology: z.string(), // e.g. "Next.js" or "FastAPI"
  is_api: z.boolean(),
})

export const IterationSchema = z.object({
  id: z.string(),
  iterationId: z.string(),
  prompt: z.string(),
  repoURI: z.string().nullable(),
  startCommitHash: z.string().nullable(),
  usecaseId: z.string(),
  sourceIterationId: z.string().nullable(),
  configuration: ConfigurationSchema,
  blueprint_id: z.string(),
  projectId: z.string(),
  teamId: z.string(),
  organizationId: z.string(),
  createdAt: TimestampSchema,
  createdBy: z.string(),
  lastInteractedBy: z.string(),
  preview_on: z.boolean(),
  toolsets: z.array(z.string()),
  is_being_extended: z.boolean(),
  preview_port_paths: z.record(z.string(), z.any()),
  preview_urls: z.array(z.any()),
  preview_urls_enhanced: z.object({ urls: z.array(PreviewUrlSchema) }).optional(),
  awaitingInputCommand: AwaitingInputCommandSchema,
  awaitingCommand: AwaitingInputCommandSchema.nullable(), // temporary
  branchName: z.string(),
  endCommitHash: z.string(),
  status: z.enum(ITERATION_STATUS_VALUES),
  vmStatus: z.enum(GUNSLINGER_JOB_STATUSES_VALUES),
  updatedAt: TimestampSchema,
  elements: z.record(z.string(), ElementSchema),
  usesTechnicalAnalyst: z.boolean(),
  usesBusinessAnalyst: z.boolean(),
  specifications: z.string().optional(), // specifications from the analyst
  continuationPrompts: z.array(ContinuationPromptSchema).optional(),
  role_registry: RoleRegistrySchema,
  isInteractiveMode: z.boolean().optional(),
  interactiveRoles: z.record(z.string(), z.boolean()).optional(),
  prompt_validation: z
    .object({
      has_any_violation: z.boolean(),
      intents: z.object({
        name: z.string(),
        violation: z.boolean(),
        violation_reason: z.string(),
      }),
      is_valid_uuid: z.boolean(),
      rewritten_prompt: z.string(),
      violation_summary: z.string(),
    })
    .optional(),
})

// Update the GetIterationOutputSchema to use IterationSchema
export const GetIterationOutputSchema = IterationSchema

// Export the Iteration type
export type Iteration = z.output<typeof IterationSchema>
export type GetIterationOutput = z.infer<typeof GetIterationOutputSchema>

// Input type for getIteration function
export type GetIterationInput = {
  iterationId: string
}

export const IterationDefaultsSchema = z.object({
  createdAt: TimestampSchema,
  createdBy: z.string(),
  projectId: z.string(),
  repository: z.object({
    githubKey: EncryptedValueSchema,
    repoURI: z.string(),
  }),
  environment: z.record(z.string(), EncryptedValueSchema),
})

export type IterationDefaults = z.infer<typeof IterationDefaultsSchema>

const UserCommandStatusSchema = z.enum([
  'PENDING',
  'RECEIVED',
  'ACCEPTED',
  'REJECTED',
  'IN_PROGRESS',
  'SUCCESS',
  'FAILED',
])
export type UserCommandStatus = z.infer<typeof UserCommandStatusSchema>

export const UserCommandSchema = z.object({
  id: z.string().optional(), // id can be optional for some god forsaken reason

  command: z.string(),
  args: z.record(z.any()),

  teamId: z.string().nullish(), // teamId is not present for devl (locally ran) iterations.
  organizationId: z.string(),
  projectId: z.string(),
  iterationId: z.string(),

  status: UserCommandStatusSchema,

  createdAt: TimestampSchema,
  updatedAt: TimestampSchema,
})

export type UserCommand = z.output<typeof UserCommandSchema>

const inputRequestEntrySchema = z.object({
  request: z.string(),
  label: z.string(),
  type: z.enum(['text', 'secret']),
  user_value: z.string().optional(),
})

export const InputRequestCommandSchema = UserCommandSchema.extend({
  command: z.literal(ITERATION_COMMANDS.INPUT_REQUEST),
  args: z.object({
    requests: z.array(inputRequestEntrySchema),
  }),
})

export type InputRequestCommand = z.infer<typeof InputRequestCommandSchema>

export function isInputRequestCommand(command: UserCommand): command is InputRequestCommand {
  if (
    command.command === ITERATION_COMMANDS.INPUT_REQUEST &&
    !InputRequestCommandSchema.safeParse(command).success
  ) {
    console.info(command, InputRequestCommandSchema.safeParse(command).error)
  }

  return (
    (command as InputRequestCommand).command === 'INPUT_REQUEST' &&
    InputRequestCommandSchema.safeParse(command).success
  )
}
