import { UserCommand } from '@/services/firebase_shared_types.ts'
import PromptBubble from '@/components/organisms/project-details/OIterationDetails/MPromptBubble.tsx'

export function SuggestionPromptBubble(props: {
  suggestion: string
  status: UserCommand['status']
}) {
  return (
    <PromptBubble status={props.status}>
      <span>{props.suggestion}</span>
    </PromptBubble>
  )
}
