import { cn } from '@/lib/utils.ts'
import { AlarmClock, CheckCheck, OctagonX } from 'lucide-react'
import { ElementType } from 'react'
import { UserCommandStatus } from '@/services/firebase_shared_types.ts'
import ALogoSpinner from '@/components/atoms/ALogoSpinner.tsx'

const AWAITING_BUBBLE_CONFIG = {
  Icon: AlarmClock,
  className: 'border border-dashed border-border bg-transparent rounded-xl',
}
const ERRORED_BUBBLE_CONFIG = {
  Icon: (props: { className: string }) => (
    <OctagonX className={cn('text-red-500', props.className)} />
  ),
  className: 'bg-red-50',
}
const REJECTED_BUBBLE_CONFIG = {
  ...ERRORED_BUBBLE_CONFIG,
  statusLabel: 'Violates Proofs Policies and Terms of Services',
}
const IN_PROGRESS_BUBBLE_CONFIG = {
  Icon: (props: { className: string }) => (
    <ALogoSpinner className={cn('text-violet-500', props.className)} />
  ),
  className: 'bg-violet-50',
}
const SUCCESS_BUBBLE_CONFIG = { Icon: CheckCheck }

type BubbleConfig = {
  Icon: ElementType | ((props: { className: string }) => React.ReactElement)
  className?: string
  statusLabel?: string
}

const BUBBLE_CONFIG: Record<UserCommandStatus, BubbleConfig> = {
  PENDING: AWAITING_BUBBLE_CONFIG,
  RECEIVED: AWAITING_BUBBLE_CONFIG,
  ACCEPTED: AWAITING_BUBBLE_CONFIG,
  REJECTED: REJECTED_BUBBLE_CONFIG,
  IN_PROGRESS: IN_PROGRESS_BUBBLE_CONFIG,
  SUCCESS: SUCCESS_BUBBLE_CONFIG,
  FAILED: ERRORED_BUBBLE_CONFIG,
}

export default function PromptBubble({
  children,
  className,
  status = 'SUCCESS',
}: {
  children: React.ReactNode
  className?: string
  icon?: ElementType
  status?: UserCommandStatus
}) {
  const {
    Icon,
    className: configClassName = '',
    statusLabel,
  } = BUBBLE_CONFIG[status] ?? AWAITING_BUBBLE_CONFIG
  return (
    <div className={cn('flex flex-col', className)}>
      <div
        className={cn(
          'ml-auto flex w-auto max-w-[90%] items-start gap-3 rounded-xl bg-base-accent px-4 py-3 tracking-tighter',
          'opacity-100 transition-[opacity,transform] starting:translate-y-4 starting:opacity-0',
          configClassName
        )}
      >
        <Icon className={cn('mt-0.5 size-4')} />
        <p className="whitespace-pre-line text-sm text-base-foreground">{children}</p>
      </div>
      {statusLabel && (
        <p className="self-end whitespace-pre-line text-sm text-base-foreground">{statusLabel}</p>
      )}
    </div>
  )
}
