import { AudioWaveform, LayoutTemplate, Rocket, ShoppingCart } from 'lucide-react'
import { Button } from '../ui/button.tsx'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import FormField from '@/components/ui/form-field.tsx'
import { useMutation } from '@tanstack/react-query'
import useStore from '@/stores/useStore.js'
import { CreateProjectPayload } from './project-details/CreateProject/useCreateProject.ts'
import { ABorderBox } from '@/components/atoms/ABorderBox.tsx'
import {
  createProjectAndIterationFirebaseFunction,
  generateFirebaseId,
  COLLECTIONS,
} from '@/services/Firebase.ts'
import { ConfigurationTemplate } from './project-details/CreateProject/types.ts'
import { useNavigate } from 'react-router-dom'
import ALogoSpinner from '@/components/atoms/ALogoSpinner.tsx'
import { useCallback, useState, useEffect } from 'react'

export function MProjectPrompt() {
  const navigate = useNavigate()

  const { organizationId, teamId } = useBaseProjectPayload()

  const form = useForm<PromptData>({
    resolver: zodResolver(promptSchema),
    reValidateMode: 'onChange',
    defaultValues: {
      prompt: '',
      usecaseId: 'custom',
      organizationId,
      teamId,
    },
  })

  const isValid = form.formState.isValid

  const mutation = useCreateProjectMutation()

  const onSubmit = async (data: PromptData) => {
    const projectId = generateFirebaseId(COLLECTIONS.projects)
    const iterationId = generateFirebaseId(COLLECTIONS.iterations)

    mutation.mutate({
      ...data,
      projectId,
      iterationId,
    })
    navigate(`/projects/${projectId}?iteration=${iterationId}`)
  }

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
        e.preventDefault()
        if (isValid && !mutation.isPending) {
          form.handleSubmit(onSubmit)()
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [isValid, mutation.isPending, form.handleSubmit, onSubmit])

  const [isFocused, setIsFocused] = useState(false)

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <h2 className="mb-4 font-pp-supply-sans text-3xl font-bold tracking-tight">
        What do you want to build today?
      </h2>
      <ABorderBox
        isEnabled={mutation.isPending || isFocused}
        borderClasname="text-[#EEAA67]"
        className="rounded-md bg-white"
      >
        <FormField.Root control={form.control} name="prompt" id={TEXTAREA_ID}>
          <FormField.Textarea
            {...form.register('prompt')}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            autogrow={true}
            variant="ghost"
            className="resize-none border-transparent bg-white shadow-none focus-visible:ring-0"
            placeholder="Describe your project..."
          />
          <FormField.Error />
        </FormField.Root>
        <div className="p-4 text-right">
          <Button className="ml-auto" disabled={!isValid || mutation.isPending} type="submit">
            {mutation.isPending ? (
              <ALogoSpinner className="size-4" />
            ) : (
              <Rocket className="size-4" />
            )}
            Build
          </Button>
        </div>
      </ABorderBox>
      <div className="mt-2 flex gap-2">
        {prompts.map(prompt => (
          <Button
            size="sm"
            variant="outline"
            type="button"
            key={prompt.name}
            onClick={() => {
              form.setValue('prompt', prompt.prompt, { shouldValidate: true, shouldDirty: true })
              form.setFocus('prompt')
            }}
          >
            <prompt.icon className="size-4" />
            {prompt.name}
          </Button>
        ))}
      </div>
    </form>
  )
}

const promptSchema = z.object({
  prompt: z.string().trim().nonempty(),
  usecaseId: z.literal('custom'),
  organizationId: z.string(),
  teamId: z.string(),
})

function useCreateProjectMutation() {
  const usecase = useUsecase()
  return useMutation({
    mutationFn: async ({
      organizationId,
      teamId,
      prompt,
      projectId,
      iterationId,
    }: PromptData & { projectId: string; iterationId: string }) => {
      const projectConfiguration: CreateProjectPayload['formData']['projectConfiguration'] = {
        name: null,
        organizationId,
        prospectName: null,
        prospectWebsite: null,
        techStack: 'custom',
        teamId,
      }

      return await createProjectAndIterationFirebaseFunction({
        projectId,
        iterationId,
        organizationId,
        teamId,
        projectData: {
          name: null,
          projectConfiguration,
          iterationDefaults: {
            environment: {
              USE_BUSINESS_ANALYST: {
                dontEncrypt: true,
                value: 'true',
              },
              USE_TECHNICAL_ANALYST: {
                dontEncrypt: true,
                value: 'false',
              },
            },
            repository: {
              githubUsername: null,
            },
            usecase,
          },
        },
        prompt,
        dontStartGunslinger: false,
        configuration: projectConfiguration,
      })
    },
  })
}

function useBaseProjectPayload() {
  const organizationsOptions = useStore(state =>
    state.organizations.filter(org => !org.is_reference)
  )
  const organizationId = organizationsOptions[0].id
  const teams = useStore(state => state.getTeamsByOrgId(organizationId))
  const teamId = teams[0].id
  return { organizationId, teamId }
}

function useUsecase() {
  const usecase = useStore(state =>
    state.getConfigurationTemplateByUsecaseId('custom')
  ) as ConfigurationTemplate

  const useCaseFields = usecase.iterationDefaultsTemplate.usecase?.fields ?? []

  return useCaseFields.reduce(
    (acc, { key, value }) => {
      acc[key] = value
      return acc
    },
    {} as Record<string, string>
  )
}

type PromptData = z.infer<typeof promptSchema>

export function useFocusPrompt() {
  const focus = useCallback(() => {
    const promptElement = document.getElementById(TEXTAREA_ID)
    if (promptElement) {
      promptElement.focus()
    }
  }, [])

  return focus
}

const TEXTAREA_ID = 'project-prompt-textarea'

const prompts = [
  {
    name: 'Landing page',
    prompt:
      'Build a Marketing Landing Page for my company [name here]. Use Shadcn, and a NextJS. It should have a nice hero image at the top taking up the full width. Underneath the hero image it should contain nicely designed images in a flex grid layout where its alternating pictures of products with slogans',
    icon: LayoutTemplate,
  },
  {
    name: 'Audio visualizer',
    prompt:
      'Create an app that captures microphone input and generates a beautiful waveform visualization.',
    icon: AudioWaveform,
  },

  {
    name: 'E-commerce PoC for a prospect',
    prompt: `We want to create an ecommerce shop demonstration that reproduce the branding of our prospect, North Face. Their website is https://www.thenorthface.com/en-us

We want:

1. a proof of concept website using React
2. the main page should display some marketing content
3. there are two categories of products
4. there are at least 3 products per categories

The whole website and content should be branded like the prospect.`,
    icon: ShoppingCart,
  },
]
